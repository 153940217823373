<template>
  <div class="home">
    <div v-if="maintenance">
      <span>Le site est en maintenance, merci de revenir un peu plus tard.</span><br>
      <img src="@/assets/site-en-construction.png">
    </div>
    <img src="@/assets/fin-beta.jpg" v-else>
    <br>
    <span v-if="redirect">Redirection vers <a :href="redirect">{{ redirect }}</a> dans {{ timeBeforeRedirect }} secondes...</span>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data: () => {
    return {
      timeBeforeRedirect: 5,
    }
  },
  components: {},
  created() {
    if (this.redirect) {
      window.setInterval(() => {
        if (this.timeBeforeRedirect > 0) {
          this.timeBeforeRedirect--;
        } else {
          window.location = this.redirect;
        }
      }, 1000)
    }
  },
  computed: {
    maintenance() {
      return process.env.VUE_APP_MAINTENANCE === 'true'
    },
    redirect() {
      return process.env.VUE_APP_REDIRECT;
    }
  }
}
</script>
<style scoped>
.home {
  padding: 5px;
}
span{
  line-height: 5;
}
img {
  margin: auto;
  max-width: 100vw;
  padding: 0;
}
</style>
